<script setup lang="ts">
import ProfileIcon from '~/assets/images/icons/profile.svg?component'
import type { VRoundedPictureProps } from '~/components/atoms/VRoundedPicture/VRoundedPicture.vue'

interface VAsideHeadContent {
    buttonProps?: Object
    displayProfileIcon?: boolean
    additionalMediaProps?: VRoundedPictureProps
}

const props = withDefaults(defineProps<VAsideHeadContent>(), { displayProfileIcon: true })

const slots = useSlots()
const hasAdditionalMedia = computed(() => {
    const media = props.additionalMediaProps
    return media?.file || media?.entity?.image || slots['additional-media']
})
</script>

<template>
    <VButton
        v-if="buttonProps"
        outlined
        :label="$t('back_link')"
        :class="$style.back"
        :icon-last="false"
        v-bind="buttonProps"
    >
        <template #icon>
            <SvgIcon name="chevron-left" view-box="0 0 24 24" width="24" height="24" />
        </template>
    </VButton>

    <slot />

    <div v-if="hasAdditionalMedia" :class="$style['aside-medias']">
        <ProfileIcon viewBox="0 0 108 108" :class="$style.media" />
        <slot name="additional-media" :media-class="[$style.media, $style['media--additional']]">
            <VRoundedPicture
                size="108px"
                :class="[$style.media, $style['media--additional']]"
                v-bind="additionalMediaProps"
            />
        </slot>
    </div>
    <ProfileIcon v-else-if="displayProfileIcon" viewBox="0 0 108 108" :class="[$style.media, $style['media--solo']]" />
</template>

<style lang="scss" module>
.back {
    margin-bottom: rem(32);
}

.aside-medias {
    display: flex;
    margin-bottom: rem(32);
}

.media {
    width: rem(69);
    height: auto;

    @include media('>=md') {
        width: rem(108);
    }

    &--solo {
        margin-bottom: rem(24);

        @include media('>=md') {
            margin-bottom: rem(32);
        }
    }

    &--additional {
        z-index: 1;
        margin-left: rem(-30);
    }
}
</style>
